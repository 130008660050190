import { Message } from '@api';

import { CopyButton } from '@ui/uikit/components/common/CopyButton';

import { DislikeButton } from './DislikeButton';
import { LikeButton } from './LikeButton';

const buttonClassname = 'msq-btn msq-btn-icon-sm msq-btn-secondary h-6';

type Props = {
  message: Message;
};

export const ContextButtons = ({ message }: Props) => {
  const showRateButtons = Boolean(
    message?.id && (message.ratable || typeof message.useful === 'boolean'),
  );

  return (
    <div className="flex gap-1 text-base-text-tertiary">
      <CopyButton value={message.content} className={buttonClassname} />
      {showRateButtons && (
        <>
          <LikeButton message={message} className={buttonClassname} />
          <DislikeButton className={buttonClassname} message={message} />
        </>
      )}
    </div>
  );
};
