import { ReactNode } from 'react';

import classNames from 'classnames';

import { BalanceHistoryTab } from './BalanceHistoryTab/BalanceHistoryTab';
import { GetCreditsTab } from './GetCreditsTab/GetCreditsTab';

export enum BalanceTabName {
  CREDITS = 'CREDITS',
  HISTORY = 'HISTORY',
}

type BalanceTab = {
  tabName: BalanceTabName;
  title: string;
  component: ReactNode;
};

const BALANCE_TABS_CONFIG: Record<BalanceTabName, BalanceTab> = {
  [BalanceTabName.CREDITS]: {
    tabName: BalanceTabName.CREDITS,
    title: 'Get Credits',
    component: <GetCreditsTab />,
  },
  [BalanceTabName.HISTORY]: {
    tabName: BalanceTabName.HISTORY,
    title: 'Balance History',
    component: <BalanceHistoryTab />,
  },
};

const BALANCE_TABS: BalanceTabName[] = [BalanceTabName.CREDITS, BalanceTabName.HISTORY];

type BalanceTabsProps = {
  activeTab: BalanceTabName;
  onClickTab: (tab: BalanceTabName) => void;
};

export const BalanceTabs = ({ activeTab, onClickTab }: BalanceTabsProps) => {
  return (
    <div className="w-full">
      <div className="flex flex-row gap-10 overflow-auto hide-scrollbar border-b border-neutral-60">
        {BALANCE_TABS.map((tabName) => (
          <button
            className={classNames('pb-6 font-semibold text-nowrap', {
              'border-b border-neutral-700': activeTab === tabName,
            })}
            onClick={() => onClickTab(tabName)}
          >
            {BALANCE_TABS_CONFIG[tabName].title}
          </button>
        ))}
      </div>
      {BALANCE_TABS_CONFIG[activeTab].component}
    </div>
  );
};
