import { useAiAssistantStatusQuery } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';

import { MagicAi } from '@ui/uikit/components/icons/poly/MagicAi';

import { AiAgentScreens } from './ChatDialog';
import { BalanceScreen } from './screens/BalanceScreen/BalanceScreen';
import { ChatScreen } from './screens/ChatScreen/ChatScreen';

type ChatContentProps = {
  activeScreen: AiAgentScreens;
  backToChat: () => void;
};

export const ChatContent = ({ activeScreen, backToChat }: ChatContentProps) => {
  const { user, onLogin } = useNavLayout();

  const { data, isPending } = useAiAssistantStatusQuery({ enabled: !!user });

  if (!user) {
    return (
      <div className="w-full flex flex-col justify-center items-center gap-6 text-center h-full">
        <span className="msq-btn msq-btn-icon-lg msq-btn-brand-gradient rounded-full">
          <MagicAi className="icon-xs" />
        </span>
        <span className="text-caption-md-a">
          Hello! I'm Mystic, your AI assistant <br />
          for navigating the Web3 world.
        </span>
        <span className="text-caption-md-a">Connect your wallet to get started.</span>
        <button className="msq-btn msq-btn-lg msq-btn-primary" onClick={onLogin}>
          Connect
        </button>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="flex justify-center items-center h-full">
        <h2 className="text-h2-a">Loading...</h2>
      </div>
    );
  }

  if (!isPending && !data?.isAvailable) {
    return (
      <div className="flex justify-center items-center h-full">
        <h2 className="text-h2-a">Chat is not avalible</h2>
      </div>
    );
  }

  if (activeScreen === 'chat') {
    return <ChatScreen />;
  }

  if (activeScreen === 'balance') {
    return <BalanceScreen backToChat={backToChat} />;
  }
};
