import { Message } from '@api';

import * as Dialog from '@radix-ui/react-dialog';
import { CustomDialog } from '@ui/uikit/components/common/Dialog';

import { DislikeMessageForm } from './DislikeMessageForm';

type RateMessageModalProps = Dialog.DialogProps & {
  message: Message;
  closeModal: () => void;
};

export const DislikeMessageModal = ({ message, closeModal, ...props }: RateMessageModalProps) => {
  const onSubmitHandler = () => {
    closeModal();
  };

  return (
    <CustomDialog
      {...props}
      title="Share your feedback"
      titleIsLeftAligned
      size="sm"
      usePortal={false}
    >
      <DislikeMessageForm message={message} onSubmit={onSubmitHandler} />
    </CustomDialog>
  );
};
