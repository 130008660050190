import { useState } from 'react';

import { useAiAssistantStatusQuery, useUserSqrvBalance } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';
import { formatNumberLocalized } from '@shared/helpers/formatters';

import { InfoTooltip } from '@ui/uikit/components/common/Tooltip';
import { ArrowLeft } from '@ui/uikit/components/icons/mono';

import { BalanceTabName, BalanceTabs } from './BalanceTabs/BalanceTabs';

type CreditsProps = {
  backToChat: () => void;
};

export const BalanceScreen = ({ backToChat }: CreditsProps) => {
  const [activeTab, setActiveTab] = useState<BalanceTabName>(BalanceTabName.CREDITS);

  const { user } = useNavLayout();

  const { data } = useAiAssistantStatusQuery({ enabled: !!user });
  const { data: sqrvBalance } = useUserSqrvBalance();

  return (
    <div className="w-full flex flex-col h-full">
      <div className="p-3 border-b border-base-border-light">
        <p className="text-caption-sm-a cursor-pointer" onClick={backToChat}>
          <ArrowLeft className="icon-xs" />
          Back to Chat
        </p>
      </div>
      <div className="flex flex-col items-center w-full max-h-full p-6 overflow-y-auto py-6">
        <div className="flex justify-between w-full gap-2 pb-8">
          <div className="flex flex-col border border-base-border-light w-full rounded-xl p-4 gap-2">
            <p className="text-base-text-tertiary text-caption-xs-a">
              Credits <InfoTooltip message="Tooltip text" />
            </p>
            <p className="text-h3-a">
              {formatNumberLocalized(
                (data?.usage.creditsLimit || 0) - (data?.usage.creditsUsed || 0) || 0,
              )}{' '}
              of {formatNumberLocalized(data?.usage.creditsLimit || 0)} left
            </p>
          </div>
          <div className="flex flex-col border border-base-border-light w-full rounded-xl p-4 gap-2">
            <p className="text-base-text-tertiary text-caption-xs-a">
              SQRv <InfoTooltip message="Tooltip text" />
            </p>
            <p className="text-h3-a">{formatNumberLocalized(sqrvBalance?.score || 0)}</p>
          </div>
        </div>
        <BalanceTabs activeTab={activeTab} onClickTab={setActiveTab} />
      </div>
    </div>
  );
};
