import ImageProxy from '@next-image-proxy';

import { NavLayoutUser } from '@shared/common/components/NavLayout/types';

export const UserMessage = ({ message, user }: { message: string; user?: NavLayoutUser }) => {
  const srcUserAvatar = user?.data.attributes.icon;

  return (
    <div className="flex justify-end items-start gap-2 w-full">
      <span
        className="text-caption-sm-a block px-3 py-2 bg-base-bg-secondary rounded-xl"
        dangerouslySetInnerHTML={{ __html: message }}
      ></span>
      <span className="w-5 h-5 min-w-5 rounded-full overflow-hidden">
        <ImageProxy
          alt="Users's avatar"
          src={srcUserAvatar}
          width={24}
          height={24}
          className="avatar-img"
        />
      </span>
    </div>
  );
};
