import ImageProxy from '@next-image-proxy';

import { File } from '@uikit/icons/File';

import { NavLayoutUser } from '@shared/common/components/NavLayout/types';

type HelpButtonsProps = {
  helpButtons?: string[];
  user?: NavLayoutUser;
  onButtonClick: (message: string, messageIndex: number) => void;
  messageIndex: number;
};

export const HelpButtons = ({
  helpButtons = [],
  user,
  onButtonClick,
  messageIndex,
}: HelpButtonsProps) => {
  const srcUserAvatar = user?.data.attributes.icon;

  if (!helpButtons.length) {
    return null;
  }

  return (
    <div className="flex justify-end items-start gap-2 w-full">
      <div className="flex flex-wrap gap-2 justify-end">
        {helpButtons?.map((help) => (
          <button
            key={help}
            className="msq-btn msq-btn-lg msq-btn-outline text-caption-sm-a"
            onClick={() => onButtonClick(help, messageIndex)}
          >
            <File className="icon-xs text-base-text-tertiary" />
            {help}
          </button>
        ))}
      </div>
      <span className="w-5 h-5 min-w-5 rounded-full overflow-hidden">
        <ImageProxy
          alt="Users's avatar"
          src={srcUserAvatar}
          width={24}
          height={24}
          className="avatar-img"
        />
      </span>
    </div>
  );
};
