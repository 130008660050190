import Markdown from 'markdown-to-jsx';

import { Message } from '@shared/api/aiAssistant';

import { MagicAi } from '@ui/uikit/components/icons/poly/MagicAi';

import { ContextButtons } from './ContextButtons/ContextButtons';

type AiMessageProps = {
  message: Message;
};

export const AiMessage = ({ message }: AiMessageProps) => {
  return (
    <div className="flex justify-start items-start gap-2 w-full py-[10px]">
      <MagicAi className="icon-sm" />
      <div className="flex flex-col gap-2">
        <Markdown
          className="text-caption-sm-a inner-html inner-chat-html"
          options={{ wrapper: 'article' }}
        >
          {message.content}
        </Markdown>
        {!!message?.id && <ContextButtons message={message} />}
      </div>
    </div>
  );
};
