import { useMutation, useQueryClient } from '@tanstack/react-query';

import { aiAssistantKeys } from '@query';

import {
  MoleculorSocketEndpointKey,
  MoleculorSocketEndpointPayload,
  useMoleculorSocket,
} from '@shared/common/providers/socket';

export const useRateMessage = () => {
  const queryClient = useQueryClient();
  const { socket } = useMoleculorSocket();

  return useMutation({
    mutationFn: async (
      payload: MoleculorSocketEndpointPayload[MoleculorSocketEndpointKey.RATE_MESSAGE],
    ) => {
      try {
        return await socket?.emitWithAck(MoleculorSocketEndpointKey.RATE_MESSAGE, payload);
      } catch (error) {
        throw new Error('Failed to rate the message');
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: aiAssistantKeys.status });
    },
  });
};
