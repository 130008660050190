import { useAiAssistantQuotasQuery } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';
import { nextProjectUrl } from '@shared/constants/urls';
import { formatNumberLocalized } from '@shared/helpers/formatters';

import { ExternalLink } from '@ui/uikit/components/common/ExternalLink';

import { IconForBlock } from './IconForBlock';

export const GetCreditsTab = () => {
  const { user } = useNavLayout();

  const { data: quotas = [] } = useAiAssistantQuotasQuery({ enabled: !!user });

  return (
    <div className="w-full">
      <div className="py-6">
        <p className="text-caption-sm-strong-a">
          Stake SQR to earn SQRv points and unlock more monthly Mystic AI Credits
        </p>
        <p className="text-caption-xs-a">
          The more SQR you stake, the more Mystic AI monthly credits you’ll receive.
        </p>
      </div>
      <div>
        <p className="text-caption-sm-strong-a pb-4">SQRv Points Levels</p>
        <div className="flex justify-between gap-3 pb-4 flex-wrap">
          {quotas?.map((block, index) => (
            <div
              key={index}
              className="border border-base-border-light p-4 rounded-xl flex flex-col gap-2 w-[calc(50%_-_6px)]"
            >
              <IconForBlock index={index} />
              <div>
                <p className="text-caption-xs-strong-a">
                  {formatNumberLocalized(block.monthCredits)} Credits
                </p>
                <span className="text-caption-xs-a text-base-text-tertiary">Monthly</span>
              </div>
              <p className="text-caption-sm-a">
                {formatNumberLocalized(block.minSQRv)} SQRv Holders
              </p>
            </div>
          ))}
        </div>
        <ExternalLink
          href={nextProjectUrl.staking('/')}
          className="msq-btn msq-btn-primary msq-btn-lg"
        >
          Stake SQR
        </ExternalLink>
      </div>
    </div>
  );
};
