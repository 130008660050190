import {
  CircleProgress1,
  CircleProgress2,
  CircleProgress3,
  CircleProgress4,
} from '@ui/uikit/components/icons/mono';

export const IconForBlock = ({ index }: { index: number }) => {
  if (index === 0) {
    return <CircleProgress1 className="icon icon-button-sm" />;
  }

  if (index === 1) {
    return <CircleProgress2 className="icon icon-button-sm" />;
  }

  if (index === 2) {
    return <CircleProgress3 className="icon icon-button-sm" />;
  }

  return <CircleProgress4 className="icon icon-button-sm" />;
};
