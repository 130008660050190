import format from 'date-fns-tz/format';

import { useAiAssistantHistoryQuery } from '@query';

import { useNavLayout } from '@shared/common/components/NavLayout';

export const BalanceHistoryTab = () => {
  const { user } = useNavLayout();

  const { data: histories = [] } = useAiAssistantHistoryQuery({
    enabled: !!user,
    staleTime: 0,
  });

  return (
    <div className="w-full pt-6">
      <div className="grid grid-cols-2 border-b border-base-border-light pb-2 w-full">
        <p className="text-caption-xs-a text-base-text-tertiary">Amount</p>
        <p className="text-caption-xs-a text-base-text-tertiary">Date</p>
      </div>
      {histories?.map((history) => (
        <div
          className="grid grid-cols-2 border-b border-base-border-light py-4 w-full"
          key={`${history.creditsUsed}_${history.date}`}
        >
          <p>{history.creditsUsed}</p>
          <p>{format(new Date(history.date), 'd MMM yyyy')}</p>
        </div>
      ))}
    </div>
  );
};
