import { toast } from 'react-toastify';

import { Message } from '@shared/api/aiAssistant';
import { useRateMessage } from '@shared/common/components/AiAgent/api/useRateMessage';

import { ThumbsUp, ThumbsUpOutlined } from '@ui/uikit/components/icons/mono';

type LikeButtonProps = {
  className?: string;
  message: Message;
};

export const LikeButton = ({ className, message }: LikeButtonProps) => {
  const { mutate: rate } = useRateMessage();

  const onClickHandler = () => {
    if (!message.id || !message.ratable) {
      return;
    }

    rate(
      { messageId: message.id, useful: true },
      {
        onError: () => {
          toast.error('Failed to rate the message. Try again later.');
        },
      },
    );
  };

  return (
    <button onClick={onClickHandler} className={className}>
      {message.useful ? (
        <ThumbsUp className="size-4 text-base-text-green" />
      ) : (
        <ThumbsUpOutlined className="size-4 text-base-text-primary" />
      )}
    </button>
  );
};
